<div class="wrapper">
	<div class="header-wrapper">
		<ng-content select="page-header" />
	</div>
	<div class="body-wrapper">
		<ng-content select="page-body" />
	</div>
	<div class="footer-wrapper">
		<ng-content select="page-footer" />
	</div>
</div>
