import { DestroyRef, effect, inject, Injectable, signal, Signal } from '@angular/core';
import { Observable, switchMap, map, of, Subject, merge, combineLatest, filter } from 'rxjs';
import { TranslocoService } from '@jsverse/transloco';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { availableLangs, defaultLang, Language } from '../models/i18n.models';

@Injectable({
	providedIn: 'root'
})
export class InternationalizationService {

	private readonly _translateService = inject(TranslocoService);
	private readonly _route = inject(ActivatedRoute);
	private readonly _router = inject(Router);
	private readonly _destroyRef = inject(DestroyRef);

	private _currentLanguageSubject = new Subject<Language>();
	currentLanguage: Signal<Language | undefined>;
	availableLanguages = signal(availableLangs).asReadonly();

	private _storedLanguageSubject = new Subject<string>();

	constructor() {
		this.currentLanguage = this.createCurrentLanguageListener();
		this.startLanguageResolver();

		effect(() => {
			const lang = this.currentLanguage();
			if (!lang) {
				return;
			}
			this._translateService.setActiveLang(lang.code);
		});
	}

	private createCurrentLanguageListener() {
		const fromQuery$ = this._route.queryParamMap.pipe(
			map(v => v.get("lang"))
		);

		const explicit$ = fromQuery$.pipe(
			filter(v => !!v && this.isLanguageAvailable(v)),
			map(v => this.getLanguageByCode(v!))
		);

		return toSignal(merge(explicit$, this._currentLanguageSubject));
	}

	private startLanguageResolver() {
		const fromStore$ = merge(this.getFromStorage(), this._storedLanguageSubject.asObservable());
		const fromQuery$ = this._route.queryParamMap.pipe(
			map(v => v.get("lang"))
		);

		const implicit$ = combineLatest([fromStore$, fromQuery$]).pipe(
			map(([store, query]) => ({ store, query })),
			filter(v => !v.query || !this.isLanguageAvailable(v.query)),
			switchMap(v => {
				if (v.store && this.isLanguageAvailable(v.store)) {
					return of(this.getLanguageByCode(v.store));
				}
				return of(defaultLang);
			}),
			map(v => {
				if (!v || !this.isLanguageAvailable(v.code)) {
					return defaultLang;
				}
				return this.getLanguageByCode(v.code);
			}),
			takeUntilDestroyed(this._destroyRef)
		);

		implicit$.subscribe(v => {
			if (!v) {
				return;
			}
			this.applyLangParam(v.code);
		});
	}

	private getFromStorage(): Observable<string | null> {
		const code = localStorage.getItem("preferredLanguage");
		return of(code);
	}

	private saveToStorage(code: string): Observable<string> {
		localStorage.setItem("preferredLanguage", code);
		return of(code);
	}

	private getLanguageByCode(code: string): Language | undefined {
		return availableLangs.find(i => i.code.localeCompare(code, undefined, { sensitivity: 'accent' }) === 0);
	}

	private isLanguageAvailable(code: string): boolean {
		return availableLangs.some(i => i.code.localeCompare(code, undefined, { sensitivity: 'accent' }) === 0);
	}

	private applyLangParam(code: string) {
		setTimeout(() => {
			this._router.navigate(
				[],
				{
					relativeTo: this._route,
					queryParams: { lang: code },
					queryParamsHandling: 'merge',
					replaceUrl: true,
				}
			);
		}, 0);
	}

	async setCurrentLanguage(lang: Language) {
		this.saveToStorage(lang.code).subscribe(() => {
			this.applyLangParam(lang.code);
			this._storedLanguageSubject.next(lang.code);
		});
	}
}
