import { inject, Injectable } from '@angular/core';
import { map, Observable, switchMap, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IConfigurationService } from '../../../core/services/configuration/configuration.service';
import { ApiResponse } from '../../../core/models/api/api.models';
import { UserProfile } from '../../../core/models/users/user-profile';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	private readonly _http = inject(HttpClient);
	private readonly _config = inject(IConfigurationService);
	private readonly _router = inject(Router);
	private readonly _oidcService = inject(OidcSecurityService);

	private readonly _httpOptions = {
		withCredentials: true
	};

	constructor() { }

	getProfile(): Observable<UserProfile> {
		return this._config.getConfiguration().pipe(
			switchMap(v => {
				return this._http.get<ApiResponse<UserProfile>>(v.endpoints.authRoot + "/api/users/profile", this._httpOptions)
			}),
			map(v => v.data)
		);
	}

	logout(): Observable<never> {
		return this._config.getConfiguration().pipe(
			switchMap(v => {
				return this._http.get<never>(v.endpoints.authRoot + "/api/users/logout", this._httpOptions)
			}),
			tap(() => {
				this._oidcService.logoffLocal();
				this._router.navigate(['/']);
			})
		);
	}
}
