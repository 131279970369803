import { OpenIdConfiguration, StsConfigHttpLoader, StsConfigLoader } from "angular-auth-oidc-client";
import { IConfigurationService } from "../../core/services/configuration/configuration.service";
import { inject } from "@angular/core";
import { Configuration } from "../../core/models/configuration/configuration.models";
import { map } from "rxjs";

const toConfig = (config: Configuration): OpenIdConfiguration => {
	return <OpenIdConfiguration>{
		authority: config.endpoints.authRoot,
		redirectUrl: config.endpoints.appRoot,
		postLogoutRedirectUri: config.endpoints.appRoot,
		clientId: 'workspace.ui',
		scope: 'openid profile offline_access workspace.common',
		responseType: 'code',
		silentRenew: true,
		useRefreshToken: true,
		renewTimeBeforeTokenExpiresInSeconds: 30,
		secureRoutes: [config.endpoints.authRoot, config.endpoints.appRoot],
		configId: "workspace.ui"
	}
}

export const openIdConfigurationFactory = (): StsConfigLoader => {
	const configService = inject(IConfigurationService);
	const config$ = configService.getConfiguration().pipe(
		map(v => toConfig(v))
	);
	return new StsConfigHttpLoader(config$);
};
