import { inject } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

export const addSvgIcons = () => {
	const matIconRegistry = inject(MatIconRegistry);
	const domSanitizer = inject(DomSanitizer);

	matIconRegistry.addSvgIcon(
		"fusara",
		domSanitizer.bypassSecurityTrustResourceUrl("/icons/fusara-01.svg")
	);
};
