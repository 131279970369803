import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { RouterLink } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map, Observable } from 'rxjs';
import { AuthService } from '../../../auth/services/auth.service';
import { TranslocoModule } from '@jsverse/transloco';

type AuthenticationResult = {
	isAuthenticated: boolean
};

@Component({
	selector: 'app-dropdown',
	standalone: true,
	imports: [
		MatMenuModule,
		MatButtonModule,
		MatIconModule,
		NgIf,
		AsyncPipe,
		RouterLink,
		TranslocoModule
	],
	templateUrl: './dropdown.component.html',
	styleUrl: './dropdown.component.less',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownComponent {

	private readonly _authService = inject(AuthService);
	private readonly _oidcService = inject(OidcSecurityService);

	authResult$: Observable<AuthenticationResult>;

	constructor() {
		this.authResult$ = this._oidcService.isAuthenticated$.pipe(
			map(v => ({ isAuthenticated: v.isAuthenticated }))
		);
	}

	login() {
		this._oidcService.authorize();
	}

	logout() {
		this._authService.logout().subscribe();
	}
}
