import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { IConfigurationService } from '../../../core/services/configuration/configuration.service';
import { BehaviorSubject, Observable, of, take } from 'rxjs';
import { Configuration } from '../../../core/models/configuration/configuration.models';

@Injectable()
export class ConfigurationService extends IConfigurationService {

	private readonly _config: BehaviorSubject<Configuration>;
	private readonly _config$: Observable<Configuration>;

	constructor() {
		super();
		this._config = new BehaviorSubject<Configuration>({
			endpoints: {
				appRoot: environment.appRoot,
				authRoot: environment.authRoot,
				workspaceApiRoot: environment.workspaceApiRoot
			}
		});

		this._config$ = this._config.asObservable();
	}

	override getConfiguration() {
		return this._config$.pipe(take(1));
	}
}
